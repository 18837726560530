import { NgModule, APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//import { AppRoutingModule } from '../modules/app-routing.module';
import { MaterialModule } from '../modules/material.module'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { LicensesComponent } from './components/licenses/licenses.component';
import { LoginComponent } from './infrastructure/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { AboutComponent } from './components/about/about.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { ContactUsComponent } from './infrastructure/contact-us/contact-us.component';
import { ContactUsService } from './infrastructure/contact-us/contact-us.service';
import { RegisterComponent } from './infrastructure/register/register.component';
import { AuthService } from './infrastructure/login/auth.service';
import { AuthInterceptor } from './infrastructure/login/auth.interceptor';
import { AuthResponseInterceptor } from './infrastructure/login/auth.response.interceptor';
import { AuthGuardService } from './infrastructure/login/auth-guard.service';
import { ConfirmationDialogComponent } from './infrastructure/controls/confirmation-dialog.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MessageComponent } from './infrastructure/message/message.component';
import { GlobalErrorHandler } from './infrastructure/logging/GlobalErrorHandler';
import { DialogComponent } from './infrastructure/controls/dialog.component';
import { ErrorDialogModule } from './infrastructure/logging/error-dialog/error-dialog.module';
import { DialogService } from './infrastructure/controls/dialog.service';
import { environment } from '../environments/environment';
import { NotAuthComponent } from './infrastructure/not-auth/not-auth.component';
import { TestEmailComponent } from './infrastructure/test-email/test-email.component';
import { ChangePasswordComponent } from './infrastructure/change-password/change-password.component';
//import { CheckoutComponent } from './infrastructure/checkout/checkout.component';
import { CheckoutService}  from './infrastructure/services/checkout.service';
import { LogService } from './infrastructure/logging/log.service';
import { LogPublishersService } from './infrastructure/logging/log-publishers.service';
import { AppSettingsService } from './infrastructure/app-settings/appsettings.service';
import { LogWebapiService } from './infrastructure/logging/log-webapi.service';
import { SettingsService } from './infrastructure/app-settings/settings.service';
import { UserService } from './infrastructure/services/user.service';
import { PageHeaderComponent } from './infrastructure/controls/page-header/page-header.component';
import { NgxStripeModule, StripeElementsService } from 'ngx-stripe';
import { LogListComponent } from './infrastructure/logging/log-list.component';
import { ResetPasswordComponent } from './infrastructure/reset-password/reset-password.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SuccessComponent } from './components/success/success.component';
import { CancelComponent } from './components/cancel/cancel.component';
import { ProductComponent } from './components/product/product.component';
import { BuyComponent } from './components/childComponents/buy/buy.component';
import { GitLabComponent } from './components/git-lab/git-lab.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ParamsComponent } from "./components/params/params.component";
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { OverviewComponent } from './components/overview/overview.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../modules/app-routing.module';
import { SortOrdersComponent } from './components/sort-orders/sort-orders.component';
import { HeyComponent } from './components/hey/hey.component';
import { SetupComponent } from './components/setup/setup.component';
import { PlayVideoComponent } from './components/play-video/play-video.component';
import { VideoListComponent } from './components/video-list/video-list.component';

// see: https://github.com/robisim74/angular-l10n/issues/179
// https://github.com/angular/angular/issues/14615
// TODO: MyDaily doesn't have authservice?
export function startupServiceFactory(authService: AuthService, settingsService: SettingsService) {
  console.log('startupServiceFactory');
  // for the token refresh
  //fetch(environment.baseUrl);
  localStorage.setItem('BASEURL', environment.baseUrl);
  
  return () => settingsService.loadAppSettingsDictionary(environment.baseUrl, authService);
}

/*
When a component is a standalone component, we have to add it to the imports array of the module, 
when it's not a standalone component, we have to add it to the declarations array of the module and also export:
*/

@NgModule({
declarations: [
    DocumentationComponent,
    ContactUsComponent,
    LicensesComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ConfirmEmailComponent,
    AboutComponent,
    DownloadsComponent,
    LoginComponent,
    ConfirmationDialogComponent,
  MessageComponent,
NotAuthComponent,
TestEmailComponent,
ChangePasswordComponent,
//CheckoutComponent,
DialogComponent,
LogListComponent,
ResetPasswordComponent,
SuccessComponent,
CancelComponent,
ProductComponent,
BuyComponent,
GitLabComponent,
PageHeaderComponent,
OverviewComponent,
ParamsComponent,
SortOrdersComponent,
HeaderComponent,
HeyComponent,
AppComponent,
SetupComponent,
PlayVideoComponent,
VideoListComponent 
    ],
  imports: [
    BrowserModule,
    //RouterModule,
    BrowserAnimationsModule,
    ErrorDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ClipboardModule,
    BrowserModule,
    AppRoutingModule,
   // HttpClientModule,
    NgxStripeModule.forRoot(environment.stripePublicKey)
    
],  
exports: [
],
  providers: [  
    {
      provide: ErrorHandler, 
      useClass: GlobalErrorHandler  
    },    
    provideClientHydration(),
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: false, autoFocus: true } },
    AuthService,
    AuthGuardService,
    ContactUsService,
    UserService,
    CheckoutService,
LogService,
LogPublishersService,
AppSettingsService,
    DialogService,
UserService,
    SettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      // diff than MyDaily
      deps: [AuthService, SettingsService],
      multi: true
    },
    AppSettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }/*,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthResponseInterceptor,
      multi: true
    }*/,
    LogWebapiService,
    StripeElementsService,
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi())
    //provideHttpClient()
    

],
schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
