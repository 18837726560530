<page-header [loading]='(authService.loading$ | async) || false' [errorMessage]='errorMessage' ></page-header>


<div class='signin-content' *ngIf="!showToken">
  <mat-card>
    <mat-card-content>
      <form [formGroup]='form'>
        <h3>Create an Account</h3>
        <mat-form-field class='full-width-input'>

          <input matInput placeholder='Email' formControlName='email' required />
          <mat-icon matSuffix>email</mat-icon>

          <mat-error *ngIf="hasError('email')">
            Please enter your email address
          </mat-error>
        </mat-form-field>
        <mat-form-field class='full-width-input'>
          <input matInput type='password' [type]="hide ? 'password' : 'text'" placeholder='Password' formControlName='password' required />
          <button mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
               <mat-icon matSuffix  (click)='hide = !hide'>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
             </button>

          <mat-error *ngIf="hasError('password')">
            Please enter a password
          </mat-error>
        </mat-form-field>
<br>
<br>
<br>

        <button mat-raised-button color='primary' (click)='onSave()' [disabled]='form.invalid' type='button'>Create
          Account</button>
      </form>

    </mat-card-content>
  </mat-card>
</div>

<div class='signin-content' *ngIf="showToken">
  <mat-card>
    <mat-card-content>
      <form [formGroup]='formToken'>
        <h3>Confirmation Token</h3>

        Please enter the token emailed to you.<br>

        <mat-form-field class='full-width-input'>
          
    
        <textarea matInput placeholder='Token' formControlName='token' rows="1" required minlength="5"></textarea>
          <mat-icon matSuffix>input</mat-icon>

          <mat-error *ngIf="hasError('token')">
            Please enter Token
          </mat-error>
        </mat-form-field>
        <br>
        <br>
        <button mat-raised-button color='primary' (click)='onConfirmEmail()' [disabled]='formToken.invalid' type='button'>Create
          Account</button>
      </form>
    
    </mat-card-content>
  </mat-card>
</div>

<br>
<a routerLink='/login' routerLinkActive='login' class='alc'><strong>Back to Login</strong></a>
<br>
